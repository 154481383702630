import requests from "./requests";
import config from "@shared/config/client";
import { withAuthenticatedClientRequest } from "../requestBuilder/withClientRequest";
import { withClientRequest } from "../requestBuilder/withClientRequest";
import { ICallableRequestBuilder } from "../requestBuilder/types";
import queryString from "query-string";

const completeFollowTwitterQuest =
  (request: ICallableRequestBuilder<void>) => (): Promise<void> => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/quests/complete/follow-on-x`,
    });

    return request.call(url, (init) => ({
      ...init,
      method: "PUT",
      credentials: "include",
    }));
  };

const checkTelegramJoinQuest =
  (request: ICallableRequestBuilder<void>) => (): Promise<void> => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/quests/complete/telegram-check`,
    });

    return request.call(url, (init) => ({
      ...init,
      method: "PUT",
      credentials: "include",
    }));
  };

const completeQuest =
  (request: ICallableRequestBuilder<void>) =>
  (id: string): Promise<void> => {
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/quests/complete/${id}`,
    });

    return request.call(url, (init) => ({
      ...init,
      method: "PUT",
      credentials: "include",
    }));
  };

const clientRequests = {
  getMyCompletedQuests: withAuthenticatedClientRequest(
    requests.getMyCompletedQuests,
  ),
  getMyUncompletedQuests: withAuthenticatedClientRequest(
    requests.getMyUncompletedQuests,
  ),
  getQuestsLeaders: withClientRequest(requests.getQuestsLeaders),
  getCompletedQuestsCount: withClientRequest(requests.getCompletedQuestsCount),
  getTotalQuestsCount: withClientRequest(requests.getTotalQuestsCount),
  completeFollowTwitterQuest: withAuthenticatedClientRequest(
    completeFollowTwitterQuest,
  ),
  checkTelegramJoinQuest: withAuthenticatedClientRequest(
    checkTelegramJoinQuest,
  ),
  completeQuest: withAuthenticatedClientRequest(completeQuest),
  getFirstUncompletedQuest: withAuthenticatedClientRequest(
    requests.getFirstUncompletedQuest,
  ),
};

export default clientRequests;
