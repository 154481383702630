import { DEFAULT_PAGE_SIZE } from "@/shared/constants";
import config from "@shared/config/client";
import queryString from "query-string";
import { ICallableRequestBuilder } from "../requestBuilder/types";
import { User } from "../users/types";

const getAllByInviterId =
  (request: ICallableRequestBuilder<User[]>) =>
  ({
    inviterId,
    skip = 0,
    take = DEFAULT_PAGE_SIZE,
  }: {
    inviterId: string;
    skip: number;
    take: number;
  }): Promise<User[]> => {
    const query = {
      skip,
      take,
    };
    const url = queryString.stringifyUrl({
      url: `${config.apiUrl}/invites/inviter/${inviterId}`,
      query,
    });

    return request.call(url);
  };

const getCountByInviterId =
  (request: ICallableRequestBuilder<number>) =>
  (inviterId: string): Promise<number> => {
    return request.call(`${config.apiUrl}/invites/inviter/${inviterId}/count`);
  };

const requests = {
  getAllByInviterId,
  getCountByInviterId,
};

export default requests;
