import requests from "./requests";
import { withAuthenticatedClientRequest } from "../requestBuilder/withClientRequest";
import config from "@shared/config/client";
import { ICallableRequestBuilder } from "../requestBuilder/types";

const inviteUsers =
  (request: ICallableRequestBuilder<any>) => async (emails: string[]) => {
    return request.call(`${config.apiUrl}/invites`, (init) => ({
      method: "POST",
      credentials: "include",
      headers: {
        ...init.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ emails }),
    }));
  };

const clientRequests = {
  getAllByInviterId: withAuthenticatedClientRequest(requests.getAllByInviterId),
  getCountByInviterId: withAuthenticatedClientRequest(
    requests.getCountByInviterId,
  ),
  inviteUsers: withAuthenticatedClientRequest(inviteUsers),
};

export default clientRequests;
